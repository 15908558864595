<template>
	<div>
		
		<deleted-orders></deleted-orders>

		<view-component
		v-if="view == 'lista'"
		@clicked="clicked"
		:show_btn_create="false"
		show_previus_days
		show_btn_pdf
		check_permissions
		:show_btn_remove_belongs_to_many="is_admin"
		model_name="order">
			<template #header>
				<total-resume></total-resume>

				<salon-en-vivo></salon-en-vivo>
			</template>
		</view-component>	
	</div>
</template>
<script>
import orders from '@/mixins/orders'
export default {
	mixins: [orders], 
	components: {
		DeletedOrders: () => import('@/components/orders/modals/DeletedOrders'),
		ViewComponent: () => import('@/common-vue/components/view/Index'),
		TotalResume: () => import('@/components/orders/components/list/total-resume/Index'),
		SalonEnVivo: () => import('@/components/orders/components/list/SalonEnVivo'),
	},
	methods: {
		clicked(order) {
			console.log('clicked order') 
			console.log(order)
			this.limpiar_intervalo_de_tiembre(order)
		},
	}
}
</script>
<style lang="sass">
.total 
	text-align: left
	margin: 1em 0
</style>